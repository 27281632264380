import React, { useState } from 'react'
import './Modal.css'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useAuth } from '../Auth/AuthContext'

export default function SendPush({open, setOpen}) {

    const [body, setBody] = useState("")
    const [sending, setSending] = useState(false)

    const { currentUser } = useAuth()

    const handleSend = () => {
        if(!body){
            toast.error("Please add message")
        } else if(body.length > 150){
            toast.error("Message too long")
        } else {
            setSending(true)
            axios.post(`${process.env.REACT_APP_API}/notifications/send-message`, {body}, {headers: {'AuthToken': currentUser.accessToken}})
            .then(()=>{
              setOpen(false)
              setBody("")
              toast.success("Sent")
            })
            .catch((e)=>{console.log(e);})
            .finally(() => {
                setSending(false)
            })
        }
    }

  return (
    <div className={open ? 'mg-modal open' : "mg-modal"} onClick={() => setOpen(false)}>
        <div className='modal-container' onClick={(e) => e.stopPropagation()}>
            <textarea 
                style={{width: '100%', height: 200, resize: 'none', borderRadius: 5, padding: 10}} 
                value={body}
                onChange={(e) => setBody(e.target.value)
                }
            />
            <p>{body.length}/150</p>

            <div style={{display:'flex', justifyContent:'space-between'}}>
                <button 
                    className='primary-btn' 
                    style={{marginRight: 5}} 
                    disabled={sending}
                    onClick={handleSend}
                >
                    {sending ? "Sending" : "Send"}
                </button>
                <button className='secondary-btn' onClick={() => setOpen(false)}>Close</button>
            </div>
        </div>
    </div>
  )
}
