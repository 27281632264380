import React, { useState, useEffect, useRef } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list';
import axios from 'axios'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment-timezone'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { useAuth } from '../Auth/AuthContext';

export default function DraftCalendar() {
    const calendarRef = useRef(null); 
  const { currentUser } = useAuth();

    const [events, setEvents] = useState([])
    const [startOfMonth, setStartOfMonth] = useState(""); 
    const [endOfMonth, setEndOfMonth] = useState(""); 
    const [generating, setGenerating] = useState(false); 
    //Modal
    const [applied, setApplied] = useState([])
    const [title, setTitle] = useState("")
    const [eventType, setEventType] = useState("")
    const [artistFee, setArtistFee] = useState("")
    const [venueFee, setVenueFee] = useState("")
    const [notes, setNotes] = useState("")
    const [setTimes, setSetTimes] = useState("")
    const [equipment, setEquipment] = useState("")
    const [id, setId] = useState("")
    const [newItem, setNewItem] = useState("")
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [date, setDate] = useState("")
    const [eventDate, setEventDate] = useState("")
    const [artistObj, setArtistObj] = useState("")
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(startOfMonth && endOfMonth){
            getDrafts()
        }
    }, [startOfMonth, endOfMonth])

    const getDrafts = () => {
        axios.post(`${process.env.REACT_APP_API}/template/get-draft-events-by-date-range`, {startOfMonth, endOfMonth})
        .then((res) => {
            setEvents(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }


    const handleClose = () =>{ 
      setOpen(false);
      setSetTimes([]);
      setEquipment([]);
      setNotes("");
      setArtistFee("");
      setVenueFee("");
      setEventType("")
  }
  
    async function generateMonthEvents() {

        setGenerating(true)
        const startDate = moment(startOfMonth).format("YYYY-MM-DD");
        const endDate = moment(endOfMonth).format("YYYY-MM-DD");
      
        axios.post(
            `${process.env.REACT_APP_API}/template/generate-month`,
            { startDate, endDate },
        )
        .then(() => {
            getDrafts()
        })
        .catch((e) => {
            console.error("Error generating month events:", e);
        })
        .finally(() => {
            setGenerating(false)
        })
      }

      const publish = () => {
        axios.post(`${process.env.REACT_APP_API}/template/publish`, { events})
        .then(() => {
            toast.success("Published")
            getDrafts()
        })
        .catch((e) => {
            toast.error("Error publishing")
        })

      }


      const updateEvent = () =>{
        setSaving(true)
        axios.post(`${process.env.REACT_APP_API}/event/draft-update`, 
          {
            _id: id, 
            payload:
              {
                start: moment(`${date}T${setTimes[0].from}`).tz("Europe/London").format(), 
                artistFee, 
                venueFee, 
                setTimes, 
                equipment, 
                eventType, 
                notes, 
              }
          }, 
        {headers: {'AuthToken': currentUser.accessToken}})
        .then((res)=>{
          toast.success("Successfully updated")
          handleClose();getDrafts();
        })
        .catch((e)=>console.log(e))
        .finally(() => setSaving(false))
        }
        

      const clearDraft = () => {
        if(window.confirm("Are you sure you want to delete?")){
            axios.post(`${process.env.REACT_APP_API}/template/delete-draft`, { events})
            .then(() => {
                getDrafts()
            })
            .catch((e) => {
                toast.error("Error publishing")
            })
        } else {
            return
        }
       
      }

      const handleDatesSet = () => {
        const calendarApi = calendarRef?.current?.getApi(); // Get FullCalendar instance
        const calendarDate = calendarApi?.getDate(); // Get the exact current date of the view
    
        const start = moment(calendarDate).startOf('month');
        const end = moment(calendarDate).endOf('month');

        setStartOfMonth(start.startOf('day'));
        setEndOfMonth(end.endOf('day'));
      };

       const handleClick = (info) =>{
         const {title} = info.event;
         const { setTimes, equipment, artistFee, venueFee, notes, _id, applied, eventType, dateStr} = info.event.extendedProps;
         let artistsGigging = events.filter((e)=> e.dateStr === dateStr ).map((e)=> e.artistId)
         let removed = applied.filter((appliedArtist)=> !artistsGigging.includes(appliedArtist))
         setOpen(true); setEventDate(dateStr)
         setDate(moment(info.event.start).format('YYYY-MM-DD'))
         setTitle(title);setArtistFee(artistFee);setVenueFee(venueFee);setNotes(notes);setSetTimes(setTimes);setEquipment(equipment);setId(_id); setApplied(removed); setEventType(eventType)
       }

  const handleChipDelete = (itemToDelete) => () => {
    setEquipment((item) => item.filter((item) => item.key !== itemToDelete.key));
  };
  const addItem = (e)=>{
    if(e.key === 'Enter'){
      console.log(newItem)
      setEquipment([...equipment, {key: equipment[equipment.length-1].key+1, label: newItem}])
      setNewItem("")
    }
  }
  const removeSet = (set) =>{
    setSetTimes((item) => item.filter((item) => item.key !== set.key));
  } 

  const addSet = () =>{
    if(!from || !to){
      toast.error("Please add from and to time")
    } else {
      let key = setTimes.length < 1 ? 1 : setTimes[setTimes.length-1].key+1
      setSetTimes([...setTimes, {key,from, to}])
      setTo("");setFrom("")
    }
  }


  const deleteEvent = () =>{
    if(window.confirm("Are you sure you want to delete event?")){
      axios.post(`${process.env.REACT_APP_API}/event/delete`, {_id: id}, {headers: {'AuthToken': currentUser.accessToken}})
      .then((res)=>{
        toast.success("Successfully deleted")
        handleClose();getDrafts();
      })
      .catch((e)=>console.log(e))
  }
  }

  return (
    <div>
        {events.length < 1 ?
            <button style={{margin: '10px 0'}} onClick={generateMonthEvents} disabled={generating}>
                Generate Month
            </button>
            :
            <div>
            <button style={{margin: '10px'}} onClick={publish}>
                Publish
            </button>
            <button style={{margin: '10px'}} onClick={clearDraft}>
                Delete Draft
            </button>
            </div>
        }       

        <FullCalendar
                ref={calendarRef} // Attach the ref to the calendar
                plugins={[ dayGridPlugin, listPlugin ]}
                nextDayThreshold='09:00:00'
                initialView="dayGridMonth"
                headerToolbar={{center:'dayGridMonth,listMonth'}}
                eventSources={[events]}
                eventClick={function(info){handleClick(info)}}
                firstDay={1}
                displayEventTime={false}
                eventContent={function( info ) {
                  return {html: `${info.event.extendedProps.setTimes[0].from} ${info.event.title}`};
                }} 
                eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: true,
                    meridiem: 'short'
                }}
                eventClassNames={'draft-event'}
                datesSet={(info) =>handleDatesSet(info)} // Callback for visible range changes

        />

<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title} - {eventType}
          </Typography>

          <FormControl sx={{mb:2}}>
          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="eventType" value={eventType} onChange={(e)=>setEventType(e.target.value)}>
            <FormControlLabel value="Solo" control={<Radio />} label="Solo" />
            <FormControlLabel value="Duo" control={<Radio />} label="Duo" />
            <FormControlLabel value="DJ" control={<Radio />} label="DJ" />
            <FormControlLabel value="Band" control={<Radio />} label="Band" />
          </RadioGroup>
        </FormControl>

    

          <TextField variant='outlined' fullWidth sx={{my:1}} label='Artist Fee' value={artistFee} onChange={(e)=>setArtistFee(e.target.value)} />
          <TextField variant='outlined' fullWidth sx={{my:1}} label='Venue Fee' value={venueFee} onChange={(e)=>setVenueFee(e.target.value)} />
          <TextField variant='outlined' fullWidth sx={{my:1}} multiline rows={2} label='Booking Notes' value={notes} onChange={(e)=>setNotes(e.target.value)} />
          
          <label>From</label>
            <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={from} onChange={(e)=>setFrom(e.target.value)}/>
            <label>To</label>
            <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={to} onChange={(e)=>setTo(e.target.value)}/>
            <button onClick={addSet} style={{backgroundColor: '#395076', color: 'white', border: 'unset', padding: '5px 10px'}}>Add</button>
              
            {setTimes.length > 0 && setTimes.map((set, i)=>{
              return(
                <div key={i} style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                  <h4 style={{color: '#395076'}}>Set {i+1}</h4>
                  <p style={{color: '#6e767b'}}>{set.from} - {set.to}</p>
                  <DeleteForeverIcon color="error" onClick={()=>removeSet(set)}/>
                </div>
              ) 
            })}
            
          
          <Paper sx={{display: 'flex', justifyContent: 'center',flexWrap: 'wrap', listStyle: 'none', p: 0.5, mb: 2,}} component="ul">
            {equipment.length > 0 && equipment.map((data) => {
              let icon;
              return (
                <ListItem key={data.key}>
                  <Chip icon={icon} label={data.label} onDelete={handleChipDelete(data)} />
                </ListItem>
              );
            })}
                  <input onKeyDown={addItem} value={newItem} onChange={(e)=>setNewItem(e.target.value)}/>
            </Paper>
          <Button variant='contained' sx={{mx:1, backgroundColor:'#395076'}} onClick={updateEvent} disabled={saving}>{saving? "Saving...": "Update"}</Button>

          <Button variant='contained' sx={{mx:1}} color='error' onClick={deleteEvent}>Delete</Button>

        </Box>
      </Modal>
    </div>
  )
}


const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
  
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '95%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign:'center',
    overflow:'scroll',
  };
  