import React, { useState, useEffect} from 'react'
import './AdminUI.css'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify'
import axios from 'axios'
import { CSVLink } from "react-csv";
import moment from 'moment-timezone'
import { useAuth } from '../Auth/AuthContext';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function VenueData({openVenue, setOpenVenue}) {
  
    let { currentUser } = useAuth()

    const [venue, setVenue] = useState('');
    const [venues, setVenues] = useState([]); 
    const [venueData, setVenueData] = useState('');
    const [venueFrom, setVenueFrom] = useState("")
    const [venueTo, setVenueTo] = useState("")
    const [loading, setLoading] = useState(false)
    const [contactEmail, setContactEmail] = useState("")

    const handleCloseVenue = () =>{ setOpenVenue(false);setVenue("");setVenueFrom("");setVenueTo("");setVenueData("")};

    useEffect(()=>{
        getVenues()
      },[])
    
      const getVenues = () =>{
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API}/venue/get-venues`, {headers: {'AuthToken': currentUser.accessToken}})
        .then((res)=>{setVenues(res.data);setLoading(false)})
        .catch((e)=>{console.log(e);setLoading(false)})
    
      }

    useEffect(()=>{
        if(venueFrom && venueTo && venue){
          axios.post(`${process.env.REACT_APP_API}/venue/get-venue-listings`,{venueId: venue, from: venueFrom, to: venueTo}, {headers: {'AuthToken': currentUser.accessToken}})
          .then((res)=>{
            setVenueData(res.data);
            console.log(res.data)
        })
          .catch((e)=>{console.log(e);})
        }
    },[venueFrom, venueTo, venue])

    useEffect(()=>{
    if(venue){
        getInfo(venue)
    }

    },[venue])

    const getInfo = (venueId) =>{
    let venueInfo = venues.find((venue)=> venue._id === venueId)
    console.log("Venue", venueInfo)
    setContactEmail(venueInfo.contactEmail)
    }
    
    const emailVenue = () =>{
        if(venueData.length < 1){
          toast.error("No Data")
        } else if(!contactEmail){
          toast.error("No Contact Email")
        } else {
          axios.post(`${process.env.REACT_APP_API}/email/venue-listings`,{data: venueData, contactEmail}, {headers: {'AuthToken': currentUser.accessToken}})
          .then((res)=>{
              console.log(res.data)
              handleCloseVenue()
          })
          .catch((e)=>{console.log(e)
          })
        }
        
      }
  return (
    <Modal
        open={openVenue}
        onClose={handleCloseVenue}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Send Venue Data
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Venue</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={venue}
              label="Venue"
              onChange={(e)=>setVenue(e.target.value)}
            >
              <MenuItem value={""}>Select Venue</MenuItem>
              {venues.map((venue, i)=>{
                return <MenuItem key={i} value={venue._id}>{venue.venueName}</MenuItem>
              })}
            </Select>
          </FormControl>
           <div >
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Select date range:
            </Typography>
            <div>
              <input type='date' value={venueFrom} onChange={(e)=>setVenueFrom(e.target.value)}/> <span> - </span>
              <input type='date' value={venueTo} onChange={(e)=>setVenueTo(e.target.value)}/>
            </div>
           
              {venueData &&
               <Button  variant='contained' sx={{my:2, backgroundColor:'#395076'}} disabled={!venueData} onClick={emailVenue}>
                       Send 
               </Button>
              }
            </div>
        </Box>
      </Modal>

  )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '90%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
    overflow: 'scroll'
  };